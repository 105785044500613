//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {planeModel} from '@/apis/tool';
export default {
	data: ()=> ({
		genType: '',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
	},
	mounted() {
		this._getList({
			genType: 380,
		})
	},
	methods: {
		async _getList({genType = ''}) {
			let {data} = await planeModel({genType})
			this.dataSource = data || []
		},
		async onSearch() {
			if(!this.genType || this.genType.length < 3) {
				this.$toast('请确认输入的飞机机型是否正确')
				return
			}
			await this._getList({genType: this.genType})
		}

	}
}
